import { Avatar, TextTooltip } from '@components/common'
import { Link } from '@components/ui'
import analytics from '@lib/analytics'
import { UserShortProfile } from '@lib/types'
import { get } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  instructor: UserShortProfile
  label?: string
  overlapName?: boolean
}

const InstructorProfile: FC<React.PropsWithChildren<Props>> = ({
  instructor,
  label = 'teacher',
  overlapName = false,
}) => {
  const { t } = useTranslation()

  return (
    <TextTooltip
      toolTipContent={instructor.firstName + ' ' + instructor.lastName}
    >
      <div className="flex items-center shrink-0 hover:block">
        <Link href={`/u/${get(instructor, 'userId', '#')}`}>
          <a
            onClick={() => {
              analytics.track(`clicked view instructor`)
            }}
            className="mr-2 flex items-center"
          >
            <div className="flex space-x-2 items-center">
              <Avatar user={instructor} size={'small'}></Avatar>

              {!overlapName && (
                <div className="">
                  <div className="p-1 font-bold">
                    {instructor.firstName + ' ' + instructor.lastName}
                  </div>
                </div>
              )}
            </div>
          </a>
        </Link>
      </div>
    </TextTooltip>
  )
}

export default InstructorProfile
