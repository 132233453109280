import { CheckIcon } from '@components/icons'
import analytics from '@lib/analytics'
import { LessonResource, SectionResource } from '@lib/types'
// import {track} from 'utils/analytics'
import { convertTimeWithTitles } from '@utils/time-utils'
import { get } from 'lodash'
import Link from 'next/link'
import * as React from 'react'
import { FunctionComponent } from 'react'
import { Element, scroller } from 'react-scroll'
import SimpleBar from 'simplebar-react'

type NextUpListProps = {
  sections: SectionResource[]
  progress: any
  courseId: string
  currentLessonId: string
  currentLessonSectionId: string
  isSession?: boolean
}

const CourseLessonList: FunctionComponent<
  React.PropsWithChildren<NextUpListProps>
> = ({
  sections,
  progress,
  currentLessonId,
  courseId,
  currentLessonSectionId,
  isSession,
}) => {
  const [activeElement, setActiveElement] = React.useState(currentLessonId)
  const scrollableNodeRef: any = React.createRef()

  React.useEffect(() => {
    if (currentLessonId) {
      setActiveElement(currentLessonId)
      scrollableNodeRef.current.id = 'scrollable-container'
      scroller.scrollTo(activeElement, {
        duration: 0,
        delay: 0,
        containerId: 'scrollable-container',
      })
    }
  }, [activeElement, setActiveElement, scrollableNodeRef])
  const LessonList = ({ lessons }) => (
    <ol>
      {lessons.map((lesson: LessonResource, index = 0) => {
        const completedLessons = get(progress, 'completed_lessons', []).map(
          (lesson: LessonResource) => lesson.id
        )
        const completed =
          lesson.completed || completedLessons.includes(lesson.id)
        return (
          <li key={lesson.id}>
            <Element name={lesson.id} />
            <div>
              <Item
                isSession={isSession}
                active={lesson.id === currentLessonId}
                lesson={lesson}
                index={index}
                completed={completed}
                courseId={courseId}
                className="hover:text-secondary-content hover:bg-secondary active:bg-primary"
              />
            </div>
          </li>
        )
      })}
    </ol>
  )
  return sections ? (
    <div className="h-full overflow-hidden">
      <div className="overflow-hidden h-96 lg:h-full rounded-md lg:rounded-none">
        {/* @ts-ignore */}

        <SimpleBar
          autoHide={false}
          className="h-full"
          scrollableNodeProps={{ ref: scrollableNodeRef }}
        >
          <div className="h-full md:max-h-350 lg:max-h-100 md:p-2">
            {sections && sections.length > 1 ? (
              sections.map((section: SectionResource, secIndex = 0) => {
                return (
                  <div key={section.id}>
                    <div
                      tabIndex={secIndex}
                      className=" collapse   collapse-open"
                    >
                      <div className=" collapse-title text-xl font-medium">
                        <Element name={section.id}></Element>
                        <SectionItem
                          active={section.id === currentLessonSectionId}
                          section={section}
                          index={secIndex}
                          completed={false}
                        ></SectionItem>
                      </div>
                      <div className=" collapse-content">
                        <LessonList lessons={section.lessons} />
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <LessonList lessons={sections[0].lessons}></LessonList>
            )}
          </div>
        </SimpleBar>
      </div>
    </div>
  ) : null
}

const Item: FunctionComponent<
  React.PropsWithChildren<{
    lesson: LessonResource
    active: boolean
    className?: string
    index: number
    completed: boolean
    courseId: string
    isSession: boolean
  }>
> = ({
  lesson,
  className,
  index,
  completed,
  active = false,
  courseId,
  isSession,
  ...props
}) => {
  const Item = () => (
    <div
      className={`group flex p-3 cursor-pointer text-base-content rounded-md ${className} ${
        active
          ? 'font-semibold bg-primary text-primary-content'
          : 'hover:text-brand active:bg-brand-dark active:text-brand'
      } transition-colors ease-in-out duration-150`}
      {...props}
    >
      <div className="flex items-start">
        <div
          className={`w-6 leading-5 pt-px text-xs ${
            completed
              ? `opacity-100 ${active ? 'text-white' : 'text-primary'}`
              : 'opacity-60 group-hover:opacity-100'
          } font-normal tracking-tight`}
        >
          {completed ? <CheckIcon /> : index + 1}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="w-full leading-tight">{lesson.name} </div>
        <div>
          <span
            className={`${
              active ? 'text-brand-200' : 'text-gray-500'
            } text-xs hover:text-brand-200`}
          >
            {convertTimeWithTitles(lesson.duration, { showSeconds: true })}
          </span>
        </div>
      </div>
    </div>
  )
  return active ? (
    <Item />
  ) : (
    <Link
      href={
        isSession
          ? `/recording/${courseId}/${lesson.slug}`
          : `/classes/${courseId}/${lesson.slug}`
      }
      onClick={() => {
        analytics.track('select_content', {
          content_type: 'lesson',
          item_id: lesson.slug,
          course_id: courseId,
        })
      }}
      className="font-semibold"
    >
      <Item />
    </Link>
  )
}

const SectionItem: FunctionComponent<
  React.PropsWithChildren<{
    section: SectionResource
    active: boolean
    className?: string
    index: number
    completed: boolean
  }>
> = ({ section, className, index, completed, active = false, ...props }) => {
  const Item = () => (
    <div
      className={`group  flex p-3 text-base-content ${className} transition-colors ease-in-out duration-150`}
      {...props}
    >
      <div className="flex flex-col">
        <div className="w-full leading-tight">{section.name} </div>
        <div>
          <span
            className={`${
              active ? 'text-brand-200' : 'text-gray-500'
            } text-xs hover:text-brand-200`}
          >
            {convertTimeWithTitles(section.duration, { showSeconds: true })}
          </span>
        </div>
      </div>
    </div>
  )
  return <Item />
}

export default CourseLessonList
