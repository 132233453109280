import { getMerchantDetails } from '@lib/services/paymentMerchant'
import { AuthUser } from 'next-firebase-auth'
import { Config, PaymentLessonType } from './constants'

const conf = ({
  productId,
  amount,
  esewaConf,
  user,
  locale,
  teamId,
  hasAdditionalCost,
}: {
  productId: string
  amount: number
  esewaConf?: any
  user: AuthUser
  locale: string
  teamId: string
  hasAdditionalCost: string
  fee?: number
}) => {
  return {
    esewa: {
      name: 'Esewa',
      icon: '/images/esewa.png',

      onPay: async () => {
        const token = (await getMerchantDetails(user, locale, 1, teamId)).secret
        // const token = 'EPAYTEST'

        var path = process.env.NEXT_PUBLIC_ESEWA_URL
        var params = {
          tAmt: esewaConf.fee ? amount + esewaConf.fee : amount,
          amt: esewaConf.fee ? amount + esewaConf.fee : amount,
          txAmt: 0,
          psc: 0,
          pdc: 0,
          scd: token,
          pid: encodeURI(productId),
          su: esewaConf.successUrl,
          fu: esewaConf.failUrl,
        }

        var form = document.createElement('form')
        form.setAttribute('method', 'POST')
        form.setAttribute('action', path)

        for (var key in params) {
          var hiddenField = document.createElement('input')
          hiddenField.setAttribute('type', 'hidden')
          hiddenField.setAttribute('name', key)
          hiddenField.setAttribute('value', params[key])
          form.appendChild(hiddenField)
        }

        document.body.appendChild(form)
        form.submit()
      },
    },
    khalti: {
      name: 'khalti',
      icon: '/images/khalti.png',

      onPay: function ({
        productName,
        lessonType,
        userId,
        courseLessonId,
      }: {
        productName?: string
        lessonType?: number
        userId?: string
        courseLessonId?: string
      }) {
        const url = `${Config.API_BASE_URL}/api/Enrollment/khaltipayment?Identity=${productName}&Type=${lessonType}&UserId=${userId}&hasAdditionalCost=${hasAdditionalCost}`
        if (lessonType !== PaymentLessonType.Course) {
          window.location.href = url
        } else {
          window.location.href = url + `&lessonIdentity=${courseLessonId}`
        }
      },
    },
  }
}
export default conf
