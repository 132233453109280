import SelectPaymentDialoug from '@components/common/PaymentModel'
import { Button, ErrorMessageBox, Modal, useUI } from '@components/ui'
import { Config, PaymentLessonType } from '@config/constants'
import analytics from '@lib/analytics'
import { enrollCourse, enrollCourseFree } from '@lib/services'
import toaster from '@lib/utils/notify'
import { useAuthUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  courseId: string
  amount: number
  courseName: string
  lessonSlug?: string
  courseSlug: string
  teamId: string
}

const PurchaseCourseModal: FC<React.PropsWithChildren<Props>> = ({
  courseId,
  amount,
  courseName,
  courseSlug,
  lessonSlug,
  teamId,
}) => {
  const currentUser = useAuthUser()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const router = useRouter()
  const locale = router.locale

  const PAYMENT_MODEL = 'PAYMENT_MODEL'
  const { closeModal, displayModal, modalView, setModalView, openModal } =
    useUI()

  const freeCourseEnrollment = (courseId: string) => {
    setLoading(true)
    enrollCourseFree(locale, currentUser, courseId)
      .then(() => {
        setLoading(false)
        router.push(`/classes/${courseSlug}`)
        toaster.success(t('course_enroll_success_msg'))
      })
      .catch((err) => {
        setLoading(false)
        toaster.failure(t('error_enroll_msg'))
      })
  }

  const enrollCourseHandler = async (
    token: string,
    amount: number,
    type: 6 | 2
  ) => {
    setLoading(true)
    await enrollCourse(locale, currentUser, {
      courseId: courseId,
      token: token,
      amount: amount,
      paymentGateWay: type,
    })
      .then(() => {
        setLoading(false)
        toaster.success(t('course_enroll_success_msg'))
      })
      .catch((err) => {
        setLoading(false)
        toaster.failure(t('error_purchase_msg'))
      })
  }
  const [isSuccess, setIsSuccess] = useState(false)
  if (isSuccess) {
    router.reload()
  }

  useEffect(() => {
    if (router.query.success) {
      toaster.success(t('Purchase success'))
      setIsSuccess(true)
      router.push(`/classes/${courseSlug}`, undefined, {
        shallow: true,
      })
    }
    if (router.query?.fail) {
      toaster.failure(t('esewa_payment_fail'))
      router.push(`/classes/${courseSlug}`, undefined, {})
    }
  }, [router.query, currentUser.id])
  const { t } = useTranslation()

  return (
    <div className="flex flex-col justify-between">
      {modalView === PAYMENT_MODEL && (
        <Modal open={displayModal} onClose={closeModal}>
          <SelectPaymentDialoug
            teamId={teamId}
            courseLessonId={lessonSlug}
            lessonType={PaymentLessonType.Course}
            esewaConf={{
              successUrl: `${Config.API_BASE_URL}/api/student/AcceptEsewaPayment?uid=${currentUser.id}&courseId=${courseSlug}`,
              failUrl: `${
                process.env.NEXT_PUBLIC_DEPLOYMENT_URL
              }/classes/${encodeURIComponent(courseSlug)}`,
            }}
            productId={courseSlug + currentUser.id}
            productName={courseSlug}
            amount={amount}
          />
        </Modal>
      )}
      <ErrorMessageBox message={message} hasError={!!message}></ErrorMessageBox>
      <div className="flex justify-center relative mb-6">
        <h3 className="text-xl font-medium">{t('enroll_lesson')}</h3>
      </div>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row mx-auto mt-3">
          {currentUser.id ? (
            <div>
              {amount !== 0 ? (
                <Button
                  variant="slim"
                  loading={loading}
                  disabled={loading}
                  onClick={() => {
                    analytics.track('begin_checkout', {
                      currency: 'NRS',
                      items: [
                        {
                          item_id: courseId,
                          item_name: courseName,
                          currency: 'NRS',
                          price: amount,
                          quantity: 1,
                        },
                      ],
                      value: amount,
                    })
                    setModalView(PAYMENT_MODEL)
                    openModal()
                  }}
                >
                  {t('pay_rs')} {amount}
                </Button>
              ) : (
                <Button
                  variant="slim"
                  loading={loading}
                  disabled={loading}
                  onClick={() => {
                    analytics.track('begin_checkout', {
                      currency: 'NRS',
                      items: [
                        {
                          item_id: courseId,
                          item_name: courseName,
                          currency: 'NRS',
                          price: amount,
                          quantity: 1,
                        },
                      ],
                      value: amount,
                    })
                    freeCourseEnrollment(courseId)
                  }}
                >
                  {t('enroll_free')}
                </Button>
              )}
            </div>
          ) : (
            <Button
              variant="slim"
              onClick={() => {
                setModalView('LOGIN_VIEW')
                openModal()
                analytics.track('Login button clicked', {
                  location: 'lesson_detail',
                })
              }}
            >
              {t('log')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default PurchaseCourseModal
