import saveComment from '@lib/services/Comment'
import { AuthUser } from 'next-firebase-auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Comment from '../comment'
import CommentField from '../comment-field'
// import {track} from 'utils/analytics'
// import {useViewer} from 'context/viewer-context'

type CommentsProps = {
  currentUser: AuthUser
  lesson: any
  commentingAllowed: boolean
  viewer: any
}

const Comments: React.FunctionComponent<
  React.PropsWithChildren<CommentsProps>
> = ({ currentUser, lesson, commentingAllowed, viewer }: CommentsProps) => {
  // const { viewer } = useViewer()
  const [comments, setComments] = React.useState(lesson.comments)
  const router = useRouter()
  const locale = router.locale
  const courseId = '398c8620-dfa7-4d2a-ad20-232441a4fcff'
  const commentsAvailable =
    comments?.some((comment: any) => comment.state === 'published') ?? false

  const handleCommentSubmission = async (comment: string) => {
    const newComment = await saveComment(
      locale as string,
      currentUser,
      courseId,
      comment
    )
    setComments([...comments, newComment])
  }

  React.useEffect(() => {
    setComments(lesson.comments)
  }, [lesson])
  const { t } = useTranslation()
  return (
    <div className={commentsAvailable ? 'space-y-10' : 'space-y-6'}>
      {commentsAvailable
        ? comments.map((comment: any) => {
            return (
              <Comment
                key={comment.id}
                comment={comment.comment}
                state={comment.state}
                createdAt={comment.created_at}
                isCommentableOwner={comment.is_commentable_owner}
                user={comment.user}
              />
            )
          })
        : null}
      {commentingAllowed ? (
        viewer.can_comment ? (
          <CommentField onSubmit={handleCommentSubmission} />
        ) : (
          <div className="flex flex-col space-y-4 dark:text-white">
            <h4 className="font-semibold">{t('member_discussion')}</h4>
            <Link
              href="/pricing"
              // onClick={() =>
              //   track('clicked pricing', {
              //     location: 'comments',
              //   })
              // }
              className="inline-flex px-3 py-2 text-center rounded-md bg-blue-600 text-white font-semibold shadow-lg hover:bg-indigo-600 transform hover:scale-105 transition-all duration-150 ease-in-out"
            >
              {t('view_pricing')}
            </Link>
          </div>
        )
      ) : null}
    </div>
  )
}

export default Comments
