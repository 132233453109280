import { Config } from '@config/constants'
import { AuthUser } from 'next-firebase-auth'

const paymentGatewayType = {
  None: 0,

  Esewa: 1,

  Khalti: 2,

  Cash: 3,

  NIBLBank: 4,

  MBLBank: 5,

  EsewaWeb: 6,
}
type Keys = keyof typeof paymentGatewayType

// 👇️ type Values = string | number
type Values = 0 | 1 | 2 | 3 | 4 | 5 | 6

type PaymentSecret = {
  secret: string
}

export const getMerchantDetails = async (
  currentUser: AuthUser,
  locale: string,
  paymentType: Values,
  teamId: string
) => {
  const url = `/api/org/paymentSecret?type=${paymentType}`
  const token = await currentUser.getIdToken()
  const res = await fetch(`${Config.API_BASE_URL}${url}`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': locale,
      'x-team-id': teamId,
    },
  })
  if (!res.ok) throw res
  return res.json() as Promise<PaymentSecret>
}

// export const useGetMerchantDetails = (
//   currentUser: AuthUser,
//   locale: string,
//   paymentType: Values
// ) => {
//   useQuery(['OrganizationSecret Key', teamId], () =>
//     getMerchantDetails(currentUser, locale, paymentType)
//   )
// }
