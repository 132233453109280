import { default as FilePondPluginFileValidateSize } from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import { AuthUser } from 'next-firebase-auth'
import { FC, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { useTranslation } from 'react-i18next'

// Register the plugin
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

type Props = {
  onUploadSuccess: Function
  currentUser: AuthUser
  // currentFile: any
}

const SubmitAssignment: FC<React.PropsWithChildren<Props>> = ({
  onUploadSuccess,
  currentUser,
  // currentFile
}) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])

  const handleToRemoveImage = async (errRes, file) => {}
  const clearFile = () => {
    setFiles([])
  }

  return (
    <div>
      {/* @ts-ignore */}
      <FilePond
        // @ts-ignore
        credits={false}
        allowProcess={false}
        instantUpload={false}
        allowFileTypeValidation={true}
        allowMultiple={true}
        files={files}
        onupdatefiles={(fileItems) => {
          setFiles(fileItems.map((fileItem) => fileItem.file))
          onUploadSuccess(
            fileItems.map((fileItem) => fileItem.file),
            clearFile
          )
        }}
        acceptedFileTypes={[
          'image/png',
          'image/jpeg',
          'image/gif',
          'application/pdf',
          'application/x-freearc',
          'application/x-bzip',
          'application/x-bzip2',
          'application/gzip',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/vnd.oasis.opendocument.presentation',
          'application/vnd.ms-powerpoint',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/zip',
          'application/x-7z-compressed',
          'application/x-rar-compressed',
          'application/octet-stream',
          'application/octet-stream',
          'application/x-zip-compressed',
          'multipart/x-zip',
        ]}
        imagePreviewHeight={50}
        onremovefile={(errRes, file) => handleToRemoveImage(errRes, file)}
        //@ts-ignore
        allowImageResize={true}
        labelIdle={`<span style="font-size:14px">${t(
          'drag_drop_file'
        )} <span class="filepond--label-action "> ${t(
          'browse'
        )} </span> </span><br/><span
        style="font-size:11px"
          class=" text-gray-400 mt-1">${t('upload_note')}</span>`}
        server={{
          remove: null,
          revert: null,

          //processing a file
          process: async (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort
          ) => {
            onUploadSuccess(file, clearFile)

            abort()
          },
          load: async (source, load, error, progress, abort, headers) => {
            await fetch(source)
              .then(async (r) => {
                load(await r.blob())
              })
              .catch((r) => error(r))
            return {
              abort: () => {
                abort()
              },
            }
          },
        }}
      ></FilePond>
    </div>
  )
}

export default SubmitAssignment
