import { useUI } from '@components/ui'
import { PaymentLessonType } from '@config/constants'
import merchantConf from '@config/merchant.conf'
import toaster from '@lib/utils/notify'
import { useAuthUser } from 'next-firebase-auth'
import Image from 'next/image'
import { useRouter } from 'next/router'

const SelectPaymentDialoug = ({
  productId,
  productName,
  amount,
  esewaConf,
  lessonType,
  courseLessonId,
  hasAdditionalCost,
  teamId,
}: {
  productId: string
  productName: string
  amount: number
  esewaConf: any
  lessonType?: PaymentLessonType
  courseLessonId?: string
  teamId: string
  hasAdditionalCost?: boolean
}) => {
  const { closeModal } = useUI()
  const router = useRouter()
  const locale = router.locale
  const currentUser = useAuthUser()

  const merchant = merchantConf({
    locale: locale,
    user: currentUser,
    productId: productId,
    amount: amount,
    esewaConf: esewaConf,
    teamId,
    hasAdditionalCost: hasAdditionalCost ? 'true' : 'false',
  })

  return (
    <div className="m-6 w-max-2xl">
      <h1 className="p-2 py-3 mb-2 text-md font-semibold text-center">
        Select Payment Gateway.
      </h1>

      <div className="flex flex-row gap-6 md:gap-10 md:space-x-10 flex-wrap justify-around">
        {Object.values(merchant).map((value) => {
          return (
            <div
              key={value.name}
              className="p-3 border border-gray-300 shadow-lg rounded-md transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer"
              onClick={async () => {
                closeModal()
                try {
                  await value?.onPay({
                    productName,
                    lessonType,
                    userId: currentUser.id,
                    courseLessonId,
                  })
                } catch (err) {
                  toaster.failure('Unable to process payment. please try again')
                }
              }}
            >
              <Image
                src={value.icon}
                height={50}
                width={110}
                alt="payment"
              ></Image>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SelectPaymentDialoug
