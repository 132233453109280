import formatDuration from '@lib/utils/format-duration'
import React, { FC, useEffect, useState } from 'react'

interface Props {
  played: number
  duration: number
}

const RemainingTimeDisplay: FC<React.PropsWithChildren<Props>> = ({
  played,
  duration,
}) => {
  const [formattedTime, setFormattedTime] = useState('')
  useEffect(() => {
    const remainingTime = duration - duration * played
    setFormattedTime(formatDuration(remainingTime))
  }, [duration, played])
  return (
    <div>
      {formattedTime && (
        <div aria-live="off">
          <span className="text-sm">{`-${formattedTime}`}</span>
        </div>
      )}
    </div>
  )
}

export default RemainingTimeDisplay
