import Slider from 'rc-slider'
import React, { FC } from 'react'

interface Props {
  value: number
  loadedValue: number
  onSeek: (value: number) => void
  className?: string
  onSeekStart?: (value: number) => void
  onSeekComplete?: (value: number) => void
}

const SeekBar: FC<React.PropsWithChildren<Props>> = ({
  value,
  loadedValue,
  onSeek,
  className,
  onSeekStart,
  onSeekComplete,
}) => {
  return (
    <div className={className}>
      <Slider
        min={1}
        max={100}
        value={value}
        trackStyle={{
          backgroundColor: '#26ab95',
          zIndex: 3,
        }}
        handleStyle={{
          borderColor: '#26ab95',
          backgroundColor: '#26ab95',
          zIndex: 2,
        }}
        railStyle={{
          backgroundColor: '#b7efb3',
          width: `${loadedValue ?? value}%`,
          zIndex: 1,
        }}
        onChange={onSeek}
        onBeforeChange={onSeekStart}
        onAfterChange={onSeekComplete}
      />
    </div>
  )
}

export default SeekBar
