import cookieUtil from '@lib/utils/cookies'
import cn from 'classnames'
import fscreen from 'fscreen'
import { isFunction } from 'lodash'
import { useRouter } from 'next/router'
import 'rc-slider/assets/index.css'
import React, { FC, useEffect, useRef, useState } from 'react'
import ReactPlayer, { Config, ReactPlayerProps } from 'react-player'
import { RemainingTimeDisplay, SeekBar } from './controls'
import PlayerIcon from './controls/PlayerIcon'
import VolumeControlBar from './controls/VolumeControlBar'
import s from './VideoPlayer.module.css'

interface Props extends ReactPlayerProps {
  url?: string
  config?: Config
  thumbnailUrl?: string
  setCurrentPlayerState: React.Dispatch<
    React.SetStateAction<
      | 'loading'
      | 'completed'
      | 'loaded'
      | 'playing'
      | 'paused'
      | 'viewing'
      | 'buffering'
    >
  >
}

const vurilo_plr_pref_key = 'vurilo_plr_pref'

const PlayerWrapper = ({ children }) => {
  return <div className="flex-1 grow">{children}</div>
}

const playbackSpeed = [
  { label: '0.5', value: 0.5 },
  { label: 'Normal', value: 1 },
  { label: '1.25', value: 1.25 },
  { label: '1.5', value: 1.5 },
  { label: '1.75', value: 1.75 },
  { label: '2', value: 2 },
]

const VideoPlayer: FC<React.PropsWithChildren<Props>> = ({
  url,
  config,
  thumbnailUrl,
  onProgress,
  onEnded,
  setCurrentPlayerState,
}) => {
  const router = useRouter()
  const playerRef = useRef(null)
  const playerContainerRef = useRef(null)
  const controlsRef = useRef(null)
  const canvasRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [controls, setControls] = useState(false)
  const [light, setLight] = useState(true)
  const [playsinline, setPlaysinline] = useState(false)
  const [pip, setPip] = useState(false)
  const [loop, setLoop] = useState(false)
  const [volume, setVolume] = useState(0.8)
  const [muted, setMuted] = useState(false)
  const [seeking, setSeeking] = useState(false)
  const [played, setPlayed] = useState(0)
  const [loadedValue, setLoadedValue] = useState(0)
  const [hideControls, setHideControls] = useState(false)
  const [duration, setDuration] = useState(0)
  const [inFullscreenMode, setInFullscreenMode] = React.useState(false)
  const [playbackRate, setPlaybackRate] = React.useState(1.0)
  const [openPlayback, setOpenPlayback] = React.useState(false)

  const ref = useRef()

  const changePlayBack = (data: number) => {
    // @ts-ignore
    setTimeout(() => ref.current && ref.current.blur(), 100)
    setPlaybackRate(data)
    setOpenPlayback(false)
  }

  config = config ?? {
    file: {
      attributes: {
        crossOrigin: 'anonymous',
        controlsList: 'nodownload',
        onContextMenu: (e) => e.preventDefault(),
      },
    },
  }

  const togglePlay = () => {
    setPlaying(!playing)
  }

  const seek = (value: number) => {
    setPlayed(value / 100)
  }

  const handleSeekStart = () => {
    setSeeking(true)
  }

  const handleSeekComplete = (newValue: number) => {
    setSeeking(false)
    playerRef.current.seekTo(newValue / 100, 'fraction')
  }

  const handleVolumeChange = (value: number) => {
    if (value == 0) {
      setMuted(true)
    } else {
      setMuted(false)
      SavePlayerPref({ volume })
    }
    setVolume(value / 100.0)
  }

  const SavePlayerPref = ({ volume }) => {
    cookieUtil.set(vurilo_plr_pref_key, { volume })
  }
  const loadPlayerPref = () => {
    const pref = cookieUtil.get(vurilo_plr_pref_key)
    if (pref) {
      setVolume(pref.volume)
    }
  }

  useEffect(() => {
    loadPlayerPref()
  }, [])

  const toggleMute = () => {
    if (muted) {
      loadPlayerPref()
    } else {
      SavePlayerPref({ volume })
      setVolume(0)
    }
    setMuted(!muted)
  }

  const handleProgress = (state: {
    played: number
    playedSeconds: number
    loaded: number
    loadedSeconds: number
  }) => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played)
      setLoadedValue(state.loaded)
      if (onProgress && isFunction(onProgress)) {
        onProgress(state)
      }
      setCurrentPlayerState('playing')
    }
  }

  const handleReady = () => {
    setCurrentPlayerState('loading')
  }

  const onVideoCompleted = () => {
    setPlaying(false)
    setCurrentPlayerState('completed')
    if (onEnded && isFunction(onEnded)) {
      onEnded()
    }
  }

  const handleTogglePIP = () => {
    setInFullscreenMode(false)
    setPip(!pip)
  }

  const handleDisablePiP = () => {
    setPip(false)
  }

  const [hideControlTimerFunc, setHideControlTimerFunc] = useState(null)

  const autoHideControls = (hide: boolean) => {
    if (hideControlTimerFunc) clearTimeout(hideControlTimerFunc)
    if (playing === true && hide) {
      setHideControlTimerFunc(
        setTimeout(() => {
          setHideControls(true)
        }, 1000)
      )
    } else {
      setHideControls(false)
    }
  }

  const wrapperElement = useRef<HTMLDivElement>(null!)

  const toggleFullscreen = () => {
    if (inFullscreenMode) {
      fscreen.exitFullscreen()
      setInFullscreenMode(false)
    } else {
      fscreen.requestFullscreen(wrapperElement.current)
      setPip(false)
      setInFullscreenMode(true)
    }
  }

  // React.useEffect(() => {
  //   const handleRouteChange = () => {
  //     setPlaying(false)
  //   }
  //   router.events.on('routeChangeStart', handleRouteChange)
  //   return () => {
  //     router.events.off('routeChangeStart', handleRouteChange)
  //   }
  // }, [router.events, setCurrentPlayerState])

  return (
    <div
      className="h-full "
      ref={wrapperElement}
      onMouseMove={() => {
        autoHideControls(false)
      }}
      onMouseLeave={() => {
        autoHideControls(true)
      }}
    >
      <div ref={ref}></div>

      <div
        className="flex flex-col h-full"
        onClick={() => {
          togglePlay()
        }}
      >
        <ReactPlayer
          ref={playerRef}
          className="vurilo-player"
          width="100%"
          height="100%"
          url={url}
          pip={pip}
          playing={playing}
          controls={false}
          playsinline={playsinline}
          light={thumbnailUrl}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          stopOnUnmount={!pip}
          onReady={handleReady}
          onStart={() => setCurrentPlayerState('viewing')}
          onPlay={() => {
            setPlaying(true)
            setCurrentPlayerState('playing')
          }}
          onEnablePIP={() => setPip(true)}
          onDisablePIP={handleDisablePiP}
          onPause={() => {
            setPlaying(false)
            setCurrentPlayerState('paused')
          }}
          onBuffer={() => setCurrentPlayerState('buffering')}
          onEnded={onVideoCompleted}
          onClickPreview={() => setPlaying(true)}
          onProgress={handleProgress}
          onDuration={(duration: number) => {
            setDuration(duration)
            setCurrentPlayerState('loaded')
          }}
          config={config}
          playIcon={<PlayerIcon.BigCirclePlay />}
        ></ReactPlayer>
      </div>
      {url && loadedValue > 0 && (
        <div
          className={`${cn(s.playerControls)} ${
            hideControls ? 'hidden' : 'flex'
          } absolute left-0 bottom-0 p-2 md:pt-8 flex-row text-white w-full space-x-4 items-center`}
        >
          <div className="" onClick={togglePlay}>
            {playing ? <PlayerIcon.Pause /> : <PlayerIcon.Play />}
          </div>
          <div className="grow cursor-pointer">
            <div className="flex flex-row items-center space-x-3">
              <SeekBar
                className="grow"
                value={played * 100}
                onSeek={seek}
                loadedValue={loadedValue * 100}
                onSeekStart={handleSeekStart}
                onSeekComplete={handleSeekComplete}
              />
              <RemainingTimeDisplay duration={duration} played={played} />
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="hidden md:flex min-w-[90px] w-[90px] items-center">
              <div onClick={toggleMute}>
                {muted ? <PlayerIcon.VolumeMuted /> : <PlayerIcon.Volume />}
              </div>
              <VolumeControlBar
                className="grow ml-2"
                currentVolume={volume * 100}
                onValueChanged={handleVolumeChange}
              ></VolumeControlBar>
            </div>
            {/* <PlayerIcon.Settings /> */}
            {process.browser && ReactPlayer.canEnablePIP(url) && (
              <div onClick={handleTogglePIP}>
                {pip ? <PlayerIcon.PiPExit /> : <PlayerIcon.PiP />}
              </div>
            )}
            <div
              ref={ref}
              tabIndex={openPlayback ? 0 : -1}
              className={`dropdown dropdown-top dropdown-end`}
            >
              <div onClick={() => setOpenPlayback(!openPlayback)}>
                <PlayerIcon.PlayBackSpeedIcon></PlayerIcon.PlayBackSpeedIcon>
              </div>
              <ul className="dropdown-content menu p-2 shadow bg-base-100  text-black rounded-box w-52">
                {playbackSpeed.map(({ label, value }) => (
                  <li key={label} onClick={() => changePlayBack(value)}>
                    <a className={playbackRate === value && 'bg-primary'}>
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {fscreen.fullscreenEnabled && (
              <div onClick={toggleFullscreen}>
                {inFullscreenMode ? (
                  <PlayerIcon.FullScreenExit />
                ) : (
                  <PlayerIcon.FullScreen />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoPlayer
