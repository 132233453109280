import { Loading, StarRating } from '@components/common'
import { Button, Link, Time } from '@components/ui'
import analytics from '@lib/analytics'
import { CoursePageResource, ShortLessonResource } from '@lib/types'
import getCourseOgImageUrl from '@lib/utils/getCourseOgImageUrl'
import { convertTimeWithTitles, formatTimeAgo } from '@lib/utils/time-utils'
import { useAuthUser } from 'next-firebase-auth'
import { useTheme } from 'next-themes'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import InstructorProfile from '../InstructorProfile'

interface Props {
  course: CoursePageResource
}

const MarkdownDynamic = dynamic(() => import('@components/common/Markdown'), {
  loading: () => <Loading />,
  ssr: false,
})

const PlayButton: React.FunctionComponent<
  React.PropsWithChildren<{
    course: CoursePageResource
    myCourse: boolean
  }>
> = ({ course, myCourse }) => {
  const { price, isPurchase } = course
  const { t } = useTranslation()
  return (
    <div className="flex flex-col">
      <Link href={`/classes/${course.slug}`}>
        <Button
          onClick={() => {
            analytics.track(`clicked continue watching course`, {
              course: course.slug,
            })
          }}
        >
          {myCourse
            ? t('View Course')
            : isPurchase
            ? `${t('Continue Watching')}`
            : t('Start Watching')}
        </Button>
      </Link>
      {!isPurchase && price > 0 && (
        <p className="text-sm mt-2">{t('unlock_course_msg')}</p>
      )}
    </div>
  )
}

const CourseDetail: FC<React.PropsWithChildren<Props>> = ({ course }) => {
  const {
    id: courseId,
    description,
    slug,
    name,
    thumbnailUrl,
    price,
    category,
    subCategory,
    teachers,
    duration,
    lastUpdated,
    rating,
    totalStudent,
    sections,
  } = course
  const totalLessons = sections?.reduce(
    (count, current) => count + current.lessons.length,
    0
  )

  const currentUser = useAuthUser()
  const { resolvedTheme: theme, setTheme } = useTheme()
  const myCourse =
    teachers.filter((x) => x.userId === currentUser.id).length > 0
  const { t } = useTranslation()

  return (
    <div className="max-w-screen-xl mx-auto sm:pb-16 pb-8">
      <div className="mt-5 grid md:grid-cols-5 grid-cols-1 md:gap-16 gap-5 rounded-md w-full left-0 mb-4">
        <div className="md:col-span-3 md:row-start-auto flex flex-col h-full max-w-screen-2xl w-full mx-auto">
          <div className="md:hidden flex items-center justify-center">
            <a
              onClick={() =>
                analytics.track('clicked course image', {
                  course: course.slug,
                })
              }
            >
              <Image
                src={getCourseOgImageUrl(
                  teachers,
                  course.name,
                  thumbnailUrl,
                  theme
                )}
                alt={`thumbnail for ${name}`}
                height={100}
                width={200}
                quality={100}
              />
            </a>
          </div>

          <h1 className="text-xl sm:text-2xl md:text-3xl font-bold leading-tight md:text-left text-center mt-4 md:mt-0">
            {name}
            <div
              className={`ml-1  badge  badge-outline text-sx ${
                price > 0 ? ' badge-primary' : ' badge-accent'
              }`}
            >
              {price > 0 ? `Rs. ${price.toFixed(2)}` : 'Free'}
            </div>
          </h1>
          <div className="mt-4 flex flex-row items-center flex-wrap  md:items-start ">
            <div className="avatar-group -space-x-6">
              {teachers &&
                teachers.map((x) => (
                  <div className=" mt-4 hover:block" key={x.userId}>
                    <InstructorProfile
                      instructor={x}
                      overlapName={true}
                    ></InstructorProfile>
                  </div>
                ))}
            </div>
          </div>
          <div>Teachers</div>
          <div className="mt-4 flex items-center  md:mr-4">
            {duration ? (
              <div className="mr-4">
                <span className="mr-3 inline-flex items-center leading-none ">
                  <Time className="w-4 text-primary"></Time>&nbsp;
                  {convertTimeWithTitles(course.duration, {
                    showSeconds: true,
                  })}
                </span>
              </div>
            ) : (
              ''
            )}
            {lastUpdated && (
              <div>
                {t('updated')}: {formatTimeAgo(new Date(lastUpdated))}
              </div>
            )}
          </div>
          <div className="flex flex-col md:flex-row items-center md:justify-start justify-center mt-4 space-y-4 md:space-y-0 md:space-x-6 w-full">
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 sm:space-x-4 sm:flex-nowrap">
              {rating > 0 && <StarRating rating={rating} />}
              {totalStudent > 0 && (
                <div className="flex items-center flex-nowrap">
                  <div className="font-semibold mr-1">{totalStudent}</div>
                  <div className="whitespace-nowrap">{t('count_msg')}</div>
                </div>
              )}
            </div>
          </div>
          <div className="md:hidden flex items-center justify-center w-full mt-5">
            <PlayButton course={course} myCourse={myCourse} />
          </div>

          <p className="font-bold text-md mt-5 ">{t('description')}</p>
          <div className="mt-6 mb-6 overflow-auto">
            {/* @ts-ignore */}
            <MarkdownDynamic>{description}</MarkdownDynamic>
          </div>
        </div>
        <div className="md:col-span-2 flex flex-col items-center justify-start md:mb-0 mb-4">
          <div className="md:block hidden">
            <a
              onClick={() =>
                analytics.track('clicked course image', {
                  course: course.slug,
                })
              }
            >
              <Image
                src={getCourseOgImageUrl(
                  teachers.filter((x) => x.isAuthor)[0],
                  course.name,
                  thumbnailUrl,
                  theme
                )}
                alt={`thumbnail for ${name}`}
                height={180}
                width={320}
                quality={100}
              />
            </a>
          </div>

          <div className="md:block hidden space-y-6">
            <div className="w-full flex justify-center mt-10 mb-4">
              <PlayButton course={course} myCourse={myCourse} />
            </div>
          </div>
          <section className="mt-8 w-full">
            <div className="mb-2 flex flex-col space-y-4 ">
              <div className="flex flex-wrap gap-y-3 justify-between items-center rounded-sm">
                <h2 className="text-xl font-bold">{t('course_content')}</h2>{' '}
              </div>
              <div className="text-sm font-normal">
                {duration
                  ? `${convertTimeWithTitles(duration, {
                      showSeconds: true,
                    })} `
                  : ''}
                {sections?.length > 1 && `• ${sections.length} Sections`}
                {totalLessons && ` • ${totalLessons} Lessons`}{' '}
              </div>
            </div>
            <ul>
              {sections?.map((section, secIndex) => (
                <div key={section.id}>
                  <div tabIndex={secIndex} className=" collapse  collapse-open">
                    <div className=" collapse-title text-xl font-medium">
                      <div>
                        <div className="flex flex-col">
                          <div className="w-full leading-tight">
                            {section.name}{' '}
                          </div>
                          <div>
                            <span className={`text-xs hover:text-brand-200`}>
                              {convertTimeWithTitles(
                                section.lessons.reduce(
                                  (sum, current) => sum + current.duration,
                                  0
                                ),
                                { showSeconds: true }
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" collapse-content">
                      <ol>
                        {section?.lessons.map(
                          (lesson: ShortLessonResource, index = 0) => (
                            <li key={lesson.id} className="mb-2">
                              <Link href={`/classes/${slug}/${lesson.slug}`}>
                                <a
                                  onClick={() => {
                                    analytics.track('select_content', {
                                      content_type: 'lesson',
                                      item_id: lesson.slug,
                                      course_id: courseId,
                                    })
                                  }}
                                >
                                  <div className="group flex p-3 cursor-pointer rounded-md font-semibold bg-primary transition-colors ease-in-out duration-150">
                                    <div className="flex items-start">
                                      <div className="w-6 leading-5 pt-px text-xs opacity-60 group-hover:opacity-100 font-normal tracking-tight">
                                        {index + 1}
                                      </div>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full leading-tight">
                                        {lesson.name}{' '}
                                      </div>
                                      <div>
                                        <span className="text-xs">
                                          {convertTimeWithTitles(
                                            lesson.duration,
                                            { showSeconds: true }
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </Link>
                            </li>
                          )
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
              ))}
            </ul>
          </section>
        </div>
      </div>
    </div>
  )
}

export default CourseDetail
