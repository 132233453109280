const ControlButton = ({ children }) => {
  return (
    <button className="cursor-pointer rounded-sm bg-transparent hover:bg-primary h-6 w-6">
      {children}
    </button>
  )
}

const Play = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Play</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        style={{ padding: '2px' }}
        {...props}
      >
        <path d="M5 3L19 12 5 21 5 3z"></path>
      </svg>
    </ControlButton>
  )
}

const Pause = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Pause</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="mx-auto"
        style={{ padding: '2px' }}
        {...props}
      >
        <path d="M6 4H10V20H6z"></path>
        <path d="M14 4H18V20H14z"></path>
      </svg>
    </ControlButton>
  )
}

const Settings = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Settings</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        style={{ padding: '5px' }}
        className="mx-auto"
        {...props}
      >
        <circle cx="12" cy="12" r="3"></circle>
        <path d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z"></path>
      </svg>
    </ControlButton>
  )
}

const PiP = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Exit mini player</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
        className="mx-auto"
        {...props}
      >
        <path d="M0 3.5A1.5 1.5 0 011.5 2h13A1.5 1.5 0 0116 3.5v9a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 12.5v-9zM1.5 3a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5h-13z"></path>
        <path d="M8 8.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-3z"></path>
      </svg>
    </ControlButton>
  )
}

const PiPExit = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Exit Mini player</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
        className="mx-auto"
        {...props}
      >
        <path d="M1.5 2A1.5 1.5 0 000 3.5v9A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 2h-13zm7 6h5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5z"></path>
      </svg>
    </ControlButton>
  )
}

const FullScreen = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Full screen</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        style={{ padding: '5px' }}
        className="mx-auto"
        {...props}
      >
        <path d="M15 3L21 3 21 9"></path>
        <path d="M9 21L3 21 3 15"></path>
        <path d="M21 3L14 10"></path>
        <path d="M3 21L10 14"></path>
      </svg>
    </ControlButton>
  )
}

const FullScreenExit = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Exit full screen</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        style={{ padding: '3px' }}
        className="mx-auto"
        {...props}
      >
        <path d="M4 14L10 14 10 20"></path>
        <path d="M20 10L14 10 14 4"></path>
        <path d="M14 10L21 3"></path>
        <path d="M3 21L10 14"></path>
      </svg>
    </ControlButton>
  )
}

const Rewind = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Rewind</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        {...props}
      >
        <path d="M11 19L2 12 11 5 11 19z"></path>
        <path d="M22 19L13 12 22 5 22 19z"></path>
      </svg>
    </ControlButton>
  )
}

const FastForward = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Fast forward</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        {...props}
      >
        <path d="M13 19L22 12 13 5 13 19z"></path>
        <path d="M2 19L11 12 2 5 2 19z"></path>
      </svg>
    </ControlButton>
  )
}

const Volume = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Mute</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        style={{ padding: '2px' }}
        {...props}
      >
        <path d="M11 5L6 9 2 9 2 15 6 15 11 19 11 5z"></path>
        <path d="M19.07 4.93a10 10 0 010 14.14M15.54 8.46a5 5 0 010 7.07"></path>
      </svg>
    </ControlButton>
  )
}

const VolumeMuted = ({ ...props }) => {
  return (
    <ControlButton>
      <span className="sr-only">Unmute</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        style={{ padding: '2px' }}
        {...props}
      >
        <path d="M11 5L6 9 2 9 2 15 6 15 11 19 11 5z"></path>
        <path d="M23 9L17 15"></path>
        <path d="M17 9L23 15"></path>
      </svg>
    </ControlButton>
  )
}

const BigCirclePlay = ({ ...props }) => {
  return (
    <>
      <span className="sr-only">Play</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className="text-primary"
        viewBox="0 0 24 24"
        {...props}
      >
        <circle cx="12" cy="12" r="10" stroke="white"></circle>
        <path d="M10 8L16 12 10 16 10 8z" stroke="currentColor"></path>
      </svg>
    </>
  )
}

const PlayBackSpeedIcon = (props) => (
  <>
    <span className="sr-only">change speed</span>

    <svg
      height="22"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      viewBox="0 0 24 24"
      width="22"
    >
      <path
        d="M10,8v8l6-4L10,8L10,8z M6.3,5L5.7,4.2C7.2,3,9,2.2,11,2l0.1,1C9.3,3.2,7.7,3.9,6.3,5z            M5,6.3L4.2,5.7C3,7.2,2.2,9,2,11 l1,.1C3.2,9.3,3.9,7.7,5,6.3z            M5,17.7c-1.1-1.4-1.8-3.1-2-4.8L2,13c0.2,2,1,3.8,2.2,5.4L5,17.7z            M11.1,21c-1.8-0.2-3.4-0.9-4.8-2 l-0.6,.8C7.2,21,9,21.8,11,22L11.1,21z            M22,12c0-5.2-3.9-9.4-9-10l-0.1,1c4.6,.5,8.1,4.3,8.1,9s-3.5,8.5-8.1,9l0.1,1 C18.2,21.5,22,17.2,22,12z"
        fill="white"
      ></path>
    </svg>
  </>
)

const PlayerIcon = {
  Play,
  Pause,
  Settings,
  Volume,
  VolumeMuted,
  FullScreen,
  FullScreenExit,
  PiP,
  PiPExit,
  Rewind,
  FastForward,
  BigCirclePlay,
  PlayBackSpeedIcon,
}

export default PlayerIcon
