import DocumentText from '@components/icons/DocumentText'
import { fetchTeacherAssignment } from '@lib/services'
import formatDate from '@lib/utils/format-date'
import getUserFullName from '@lib/utils/getUserFullName'
import { useAuthUser } from 'next-firebase-auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, UseQueryResult } from 'react-query'

interface Props {
  courseSlug: string
  courseId: string
  lessonResourceName: string
}

const AssignmentList: FC<React.PropsWithChildren<Props>> = ({
  courseSlug,
  courseId,
  lessonResourceName,
}) => {
  const router = useRouter()
  const { t } = useTranslation()

  const currentUser = useAuthUser()
  const listTeacherAssignment: UseQueryResult<any, Response> = useQuery(
    [courseSlug, 'teacher-assignment-files', lessonResourceName],
    () => fetchTeacherAssignment(router.locale, currentUser, courseSlug)
  )
  return (
    <div>
      <>
        {listTeacherAssignment.isFetched &&
          listTeacherAssignment.data?.length === 0 && (
            <>
              <p className=" flex my-6 leading-6 item-center justify-center text-sm">
                {t('no assignment')}
              </p>
            </>
          )}

        {listTeacherAssignment.isFetched &&
          listTeacherAssignment.isSuccess &&
          listTeacherAssignment.data.map((assign) => (
            <div key={assign.id} className="mt-5">
              <div className=" rounded-lg bg-base-200  border border-base-300 px-2 py-2 ">
                <div className="">
                  <Link href={`/assignment/${courseId}/${assign.id}`} passHref>
                    <div className="flex grow items-start px-2 py-2 text-sm sm:text-base-content cursor-pointer">
                      <div className=" w-10 h-6  md:p-1 sm:pt-2 items-center inline-block">
                        <DocumentText />
                      </div>
                      <div className="flex flex-col justify-center px-2 pl-4">
                        <h3 className="flex text-md sm:text-md font-semibold leading-tighter mb-1 pt-1">
                          {assign.title}
                        </h3>

                        <div className="grid md:grid-cols-2 gap-4 ">
                          {assign.lessonName ? (
                            <div className="text-xs text-gray-500 dark:text-gray-300">
                              {t('Associated lesson')}:{assign.lessonName}
                            </div>
                          ) : (
                            ''
                          )}

                          <div className="text-xs text-gray-500 dark:text-gray-300">
                            Created By: {getUserFullName(assign.user)}
                          </div>

                          {/* <div className="text-2xs">
                          {t('due_date')} {''}
                          <time dateTime={assign.dueDate}>
                            {formatDate(assign.dueDate)}
                          </time>
                        </div> */}
                        </div>
                      </div>
                      <span className="inline text-2xs leading-tighter mb-1 pt-3 ml-auto">
                        {t('posted_on')}{' '}
                        <time dateTime={assign.createdOn}>
                          {formatDate(assign.createdOn)}
                        </time>
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </>
    </div>
  )
}

export default AssignmentList
