import { format, isValid } from 'date-fns'
import moment from 'moment'

const formatDate = (date: string, includeTime: boolean = false) => {
  const parsedDate = new Date(date)
  if (!isValid(parsedDate)) return ''
  if (includeTime) {
    return format(parsedDate, 'MMM dd, yyyy hh:mm a')
  } else {
    return format(parsedDate, 'MMM dd, yyyy')
  }
}

export const formatDateToLocal = (date: Date) => {
  return moment.utc(date).local().format('DD MMM YYYY. hh:mm a')
}

export default formatDate
