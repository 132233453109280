import { Spinner } from '@components/common'
import { Button } from '@components/ui'
import { FocusError } from '@lib/utils'
import { Formik } from 'formik'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

type CommentFieldProps = {
  onSubmit?: any
}

const CommentField: FunctionComponent<
  React.PropsWithChildren<CommentFieldProps>
> = ({ onSubmit = () => {} }) => {
  const { t } = useTranslation()
  return (
    <div className="dark:text-white">
      <Formik
        initialValues={{ newCommentText: '' }}
        onSubmit={({ newCommentText }, { resetForm }) => {
          onSubmit(newCommentText)
          resetForm()
        }}
      >
        {(props) => {
          const {
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props
          return (
            <div className="max-w-2xl">
              <form onSubmit={handleSubmit} className="w-full space-y-2">
                <textarea
                  id="newCommentText"
                  value={values.newCommentText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('comment_placeholder')}
                  disabled={isSubmitting}
                  className={`form-textarea p-3 min-h-[120px] text-base-content placeholder-gray-400 block w-full bg-base-200 border-base-300  rounded-md resize-none ${
                    isSubmitting ? 'opacity-60' : ''
                  }`}
                  required
                />
                <Button type="submit" disabled={isSubmitting} variant="slim">
                  {isSubmitting ? (
                    <>
                      {t('sending')} <Spinner size={5} className="ml-2" />
                    </>
                  ) : (
                    'Send'
                  )}
                </Button>
                <FocusError />
              </form>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

export default CommentField
